body{
    overflow-x: hidden !important;
    background-color: #F1F5F9;
    font-family: Roboto;

}

.background{
    background: transparent url("../assets/img/Shape\ \(6\).png") top left no-repeat;
}

/* Start navbar */

.navbar-collapse .btn,
.background .card .getStarted { 
padding: 4px 16px;
border: 1px solid white;
border-radius: 50px;
color: white;
font-size: 16px;
}

.background .card .getStarted a{
    color: white;
}
.navbar-collapse .btn.Login:hover,
.background .card .getStarted:hover{
border: 2px solid white;
color: white;
font-weight: bold;
}
.navbar-collapse .btn.Signup{
 background-color: white;
 color: black;
}
.navbar-collapse .btn.Signup:hover{
    box-shadow: 0 0 5px 0 #FFF2F3;
    border: 2px solid white;

}
nav.navbar{
    background-color: #475569;
}

.nav-item .nav-link .svg-inline--fa{
    font-size: 15px;
}
.nav-item .nav-link{
    color: white;
    line-height: 10px;
    font-size: 16px;
}

.nav-item .nav-link.active{
color: white;
border-bottom: 1px solid white;

}

.navbar-toggler {
    background-color: #fff;
}
/* End navbar */

/* Start mainCard */
.background .card{
    background-color: transparent;
}
.background .card .card-body{
    color: white;
    font-family: Roboto;
    text-align: left;

}
.background .card .card-body .card-title{
font-size: 54px;
font-weight: 800;
line-height: 70.4px;
/* white-space: nowrap; */
}

.background .card .card-body .card-text{
    font-size: 24px;
    font-weight: 400;
    line-height: 38.4px;
}

.background .card .getStarted{
    padding: 16px 30px ;
font-size: 24px;
font-weight: 700;
line-height: 24px;
letter-spacing: 0.5px;
text-align: left;

}
/* End mainCard */

/* start How-It-Works */
.How-It-Works{
    background-color: transparent;
}

.How-It-Works .card-title,
.AboutUs .card-title,
#select{
font-family: Roboto;
font-size: 56px;
font-weight: 800;
line-height: 61.6px;
text-align: left;
}

.How-It-Works .card-text,
 .AboutUs .card-body .card-text
{
font-family: Roboto;
font-size: 24px;
font-weight: 400;
line-height: 38.4px;
text-align: left;
}

.How-It-Works .TryYourself{
border: 1px solid black;
color: black;
border-radius: 50px;
padding: 10px 30px;
font-family: Roboto;
font-size: 20px;
line-height: 24px;
letter-spacing: 0.5px;
text-align: left;
}

.How-It-Works .TryYourself:hover{
    box-shadow: 0 0 5px 0 black;
}
/* end How-It-Works */


/* Start Features */
.Features{
    background-color: #fff;
}
.Features .card{
    background-color: transparent;
    border: none;
}

.Features .feature .card-body .card-title{
font-family: Roboto;
font-size: 25px;
font-weight: 600;
line-height: 22px;
text-align: left;
color: #0F172A;
}

.Features .card .card-body .card-text{
font-family: Roboto;
font-size: 18px;
font-weight: 400;
line-height: 28.8px;
text-align: left;
color: #0F172A;
text-decoration: none !important;

}
.select .card .card-title{
    font-weight: bold;
    font-size: 30px;
}
.select .card{
    box-shadow: 0px 4px 15px 0px #00000026;
    border-radius: 25px;
    cursor: pointer;
}


.select .card:hover{
    border: 6px solid #1B5C7A99;
}

.Features .select .card .card-body .card-text{
font-size: 16px;
font-weight: 400;
line-height: 21.6px;
text-decoration: none !important;
}
/* End Features */


.footer{
    background: transparent url("../assets/img/footer6.png")   no-repeat;
    height: 550px !important;
    margin-right: 0;
    padding-right: 0;
}

.footer .card{
    background-color: transparent;
    border: none;
}

.footer .card .card-title{
font-family: Roboto;
font-size: 56px;
font-weight: 800;
line-height: 61.6px;
text-align: center;
color: #fff;
}

.footer .card .card-body .card-text{
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: center;
    color: #fff;
}

.footer .card .card-body a{

padding: 10px 25px ;
border-radius: 8px ;
border: 2px solid #2563EB;
background-color: #2563EB;

font-family: Roboto;
font-size: 20px;
font-weight: 700;
line-height: 24px;
letter-spacing: 0.5px;
text-align: left;
color: #fff;


}
.footerCarer{
    background: transparent url("../assets/img/footer1.png")   no-repeat;

}


/* Start logo Carousel */


body {
    background-color: #f8f9fa;
    font-family: 'Arial', sans-serif;
}




@keyframes slide {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}

.logos {
    overflow: hidden;
    padding: 60px 0;
    /* background: white; */
    white-space: nowrap;
    position: relative;
}

.logos:before,
.logos:after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
}

.logos:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), #fff);
}

.logos:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
}

.logos:hover .logos-slide {
    animation-play-state: paused;
}

.logos-slide {
    display: inline-block;
    animation: 35s slide infinite linear;
}

.logos-slide img {
    height: 50px;
    margin: 0 40px;
}

/* End logo Carousel */

/* Start Creare Path */

#CareerPath .heading{
font-size: 56px;
font-weight: 800;
line-height: 61.6px;
color: #0F172A;
}

#CareerPath .textP{
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 21.86px;
    letter-spacing: -0.01em;
}

#CareerPath form .form-label{
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
color: #475569;    
}

#CareerPath form .form-control{
    padding: 15px 20px;
    border-color: black;
}

#CareerPath form .GPA{
border-radius: 10px;
border-color: black;
}

#CareerPath form .GPAText{
    border: 0;
font-size: 15px;
font-weight: 400;
line-height: 26px;
}

#CareerPath form .AreasInterest{
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    text-align: left;
    color: #475569;
}

#CareerPath form .AreasInterestp{
    font-size: 15px;
    font-weight: 300;
    line-height: 19.5px;
}


#CareerPath form .selectable-card{

    padding: 28px 10px;
    gap: 18px;
    border-radius: 8px;
    border: 0;
    box-shadow: 0px 4px 20px 0px #57575740;

}

#CareerPath form .selectable-card .card-title{
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    color:  #475569;
}

#CareerPath form .selectable-card .card-text{
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.1px;
}

#CareerPath form .UploadH4{
    font-family: Roboto;
font-size: 20px;
font-weight: 700;
line-height: 26px;
color:#475569;

}

.form-container {
    background-color: #ffffff;
    border-radius: 8px;
}

input[type="file"] + label {
    cursor: pointer;
}

.upload-box {
    border: 1.5px dashed #ccc;
    background-color: #f8f9fa;
    border: 2px dashed #ccc;
    transition: background-color 0.3s ease-in-out;
}

.upload-box:hover {
    background-color: #e9ecef;
}

#FindMyCareer{
border-radius: 50px;
padding: 10px 30px;
background-color: #475569;
font-family: Roboto;
font-size: 20px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.5px;
color:#fff;
}

#FindMyCareer .fa-chevron-righ{
font-size: 10px;
}
/* end Creare Path */

/* Start Career Results */

.CareerResults .card{
    box-shadow: 0px 1px 4px 0px #747474;
    border: 1.5px solid #1B5C7A
}

.CareerResults .card .card-title{
font-family: Roboto;
font-size: 20px;
font-weight: 500;
line-height: 24px;
text-align: left;
}

.CareerResults .card .card-text{
font-family: Roboto;
font-size: 17px;
font-weight: 400;
line-height: 22.1px;
text-align: left;
color: #767676;
}


.CareerResults .card .card-text.link{
font-family: Roboto;
font-size: 18px;
font-weight: 400;
line-height: 22px;
text-decoration: underline;
cursor: pointer;
color: #000;
}
/* End Career Results */
#CareerPath,
.CareerResults,
.payment{
    display: none;
}

.selectable-card:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.selectable-card.active {
    border: 1.5px solid #475569 !important;
    box-shadow: 0 6px 12px rgba(71, 85, 105, .4) !important;
}

footer{
    background-color: #475569;
}
footer h6{
    font-weight: bold;
}


.Account {
    max-width: 1200px;
}

.shadow-lg {
    border-radius: 15px;
}

.Account img {
    max-width: 100%;
    height: auto;
}
.formFild {
    box-shadow: 0px 4px 34.9px 0px #7F7F7F40;
border-radius: 30px 0px 0px 30px;

}

.formFild .btns{
    font-size: 15px;
    padding:15px 0px;
}

.formFild .signup{
    background-color: #1B5C7A;
    color:#fff;
}

@media (max-width : 1199px) {
    .nav-item .nav-link .svg-inline--fa{
        font-size: 11px;
    }
    .nav-item .nav-link{
        font-size: 12px;
        white-space: nowrap;
    }
    .navbar-collapse .btn{
        font-size: 12px;
        white-space: nowrap;
    }
    .background .card .card-body .card-title{
        font-size: 44px;
    }

    .select .card .card-title{
        font-size: 30px;
    }

    .footer .card .card-title{
        font-size: 44px;
        margin-top: 50px;
    }


}



@media (max-width : 991px){
    .background .card .card-body .card-title{
        font-size: 34px;
    }
    .select .card .card-title{
        font-size: 20px;
    }
    .select .card{
        padding: 25px 0px;
    }
    .footer .card .card-title{
        font-size: 35px;
    }
}

@media (max-width:797px){
    .footer .card .card-title{
        font-size: 30px;
    }
}

@media (max-width:688px){
    .footer .card .card-title{
        font-size: 25px;
    }

    .select .card .card-title{
        font-size: 40px;
    }
}
@media (max-width:453px){
    .background .card .card-body .card-text{
        font-size: 15px;
    }
    .background .card .card-body .card-title{
        font-size: 25px;
    }

    .background .card .getStarted{
        font-size: 15px;
    }
}
@media (max-width:375px){
    .footer .card .card-title{
        font-size: 15px;
        margin-bottom: 10px;
    }
    .footer .card .card-body a{
        font-size: 11px;
        padding: 5px 10px;
    }

    .footer .card .card-body .card-text{
        font-size: 12px;
    }

    .select .card .card-title{
        font-size: 40px;
    }
}